import * as React from 'react';

import './style.css';
import { makeFieldChangeHandle, makeValidateField, randomId, setFieldValue, setSelectDefaultValue } from './util';

export default function Select({field, formControls, setFormControls, className=undefined, style=undefined}){
    let id = randomId();
    const [validity, setValidity] = React.useState({valid:true, warning:""})


    let handleFieldChange = undefined;
    if(formControls!=undefined){
        const validateField = field.necessary?makeValidateField(field, setValidity, formControls, setFormControls):undefined;
        handleFieldChange = makeFieldChangeHandle(field, setFormControls, validateField);
    }
    

    return (
            <div className={"input-field select-field "+(className!=undefined?className:"")} style={field.style}>
                <label htmlFor={id}>{field.label}</label>
                <select disabled={field.disabled} value={formControls!=undefined?formControls[field.name].value:field.defaultValue} onChange={handleFieldChange!=undefined?handleFieldChange:null} className="select" id={id}>
                    <option value="" hidden></option>
                    {
                        field.options.map(({text, value}, index)=>{
                            return <option key={index} value={value}>{text}</option>
                        })
                    }
                </select>
                {!formControls[field.name].validity.valid?<div className="warning">{formControls[field.name].validity.warning}</div>:<></>}
                </div>
    )
}
