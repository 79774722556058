import * as React from 'react';

import  {Outlet} from 'react-router-dom';

import './style.css'

import AuthProvider from '../../components/AuthProvider';
import CrsNavbar from '../../components/nav/CrsNavbar';

export default function CRS(){
    return (
        <main>
            <div className="flex-row" style={{minHeight:"100vh"}}>
                <CrsNavbar/>
            <div className="crs-body">
                <Outlet/>
            </div>
            </div>
        </main>
    )
}