import * as React from 'react';

import './style.css';
import { useAuth } from '../../components/AuthProvider';
import CrsNavbar from '../../components/nav/CrsNavbar';

export default function Dashboard(){
    let auth = useAuth();
    return (
            <div style={{lineHeight:"1.4rem", width:"40vw"}}>
                <h1 style={{marginBottom:"1rem"}}>Dobrodošli.</h1>
                <p className="helpertext">Ovo je sučelje za pripremu konferencije. Ono omogućava pristup bazi podataka sa pohranjenim prijavama sudjelovanja,
                    a u bližoj budućnosti i prijava sažetaka.
                </p>
                <p className="helpertext">S lijeve strane se nalazi navigacijska traka, kako vidite postoje dvije opcije. Gornja opcija
                odvodi na sučelje za pregled prijava, gdje ćete vidjeti dvije tablice pripremljene za VIVID d.o.o. s opcijom za izvoz u Excel formatu.
                </p>
                <p className="helpertext">Zamišljeni tok aktivnosti je sljedeći:
                    
                    
                </p>
                <ol>
                        <li>Prijavitelj se prijavljuje preko forme za prijavu na web stranici</li>
                        <li>Prijava stiže u bazu podataka time je odmah vidljiva unutar ovog sučelja.</li>
                        <li>Na e-mail adresu prijavitelja stiže e-mail koji sadržava daljnje upute te vrijednosti svih unesenih polja.</li>
                        <li>HPD kontrolira prijave, te mijenjanjem statusa označava da su skladne označene kotizacije s obzirom na članstvo u HPDu ili status umirovljenika, studenta itd...</li>
                        <li>U tom će trenutku VIVID nastaviti proces </li>
                    </ol>
                    Hoće li u ovom procesu bit potrebne promjene? Možda u sadržaju e-maila koji stiže prijavitelju?
                <p className="helpertext">
                    Svaki redak u tablici ima kategoriju "akcija", opisanu sa simbolom oka.<br/>
                    Klikom na isti stranica odlazi na pregled pojedine prijave, gdje je moguće provjeriti upisane podatke. Ovo se tiče HPD-a.
                </p>
                <p className="helpertext">
                    Jedina varijabla koju je moguće mijenjati u ovoj fazi je varijabla 'status' koja trenutačno ima tri stanja: "čeka provjeru", "odobren", "odbijen".
                    U budućnosti će bit moguće, ako bude potrebe, mijenjati bilo koje polje.
                </p>
                <p className="helpertext">
                    To se odvija na sljedeći način:
                    
                </p>
                <ol>
                        <li>U sučelju za pregled pojedine prijave biramo odgovarajući status</li>
                        <li>Nakon odabira, odlazimo na dno stranice i izabiremo "Promijeni status"</li>
                        <li>Sad je status izmijenjen i promjene su vidljive.</li>
                    </ol>
                <p className="helpertext">
                    To je za sada to. Mislim da je ovaj način komunikacije učinkovitiji nego bilo koji alternativni.
                </p>
                <p>
                    <bold>Potrebna mi je informacija o "važnim datumima" koji se tiču prijave sažetaka. Trenutno ta sekcija stoji prazna na glavnoj stranici.</bold>
                </p>

                
            </div>
    )
}