import * as React from 'react';
import Table from '../table/Table';
import TableModal from '../modal/TableModal';
import { RemoveRedEye } from '@mui/icons-material';

export default function Tabular({field, formControls, setFormControls}){
    const [modalHidden, setModalHidden] = React.useState(true);
    const [modalMode, setModalMode] = React.useState("add");
    const [viewModal, setViewModal] = React.useState(<></>)

    const getHeader = ()=>{
        if(field.cols==undefined){
           return field.header.map((cell, index)=>{
                return {name:cell.name, label:cell.label, width:cell.width, show:cell.show, tableLabel:cell.tableLabel}
            })
         }
         return field.header.reduce((result, cell)=>{
            if(field.cols.indexOf(cell.name)!=-1){
                result.push({name:cell.name, label:cell.label, width:cell.width, show:cell.show, tableLabel:cell.tableLabel})
            }
            return result
         }, [])
    }

    const getFormData = () =>{
        return field.header.reduce((result, cell)=>{
            if(cell.formField!=undefined){
                result.push({name:cell.name, label:cell.label, ...cell.formField})
            }
            return result;
    
        }, [])
    }

    const defineOrder = (array) => {
        return array.map((row, index)=>{
            return {order:index+1, ...row}
        })
    }

    return (
        <>
        <div className='input-field'>
        <h3 className="form-subtitle">{field!=undefined?field.label:""}</h3>
        <Table header={getHeader()} dataSource={defineOrder(formControls[field.name].value)} layout='auto' alignHeader='left'/>
        <div className='tool-tray'><button className="modal-button" onClick={()=>{setModalHidden(false);setModalMode("add")}}>Dodaj redak</button></div>
        {!formControls[field.name].validity.valid?<div className="warning">{formControls[field.name].validity.warning}</div>:<></>}
        </div>
        {viewModal}
        <TableModal field={field} formData={getFormData()} 
        tableDataSource={formControls} 
        setTableDataSource={setFormControls}
        hidden={modalHidden} 
        setHidden={setModalHidden} 
        modalMode={modalMode} 
        setModalMode={setModalMode}
        />
        </>
    )
}