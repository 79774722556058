import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_org.css'
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';

import { Link } from 'react-router-dom';

import {isBrowser, isMobile } from 'react-device-detect';

export default function Book(){

    return (
        <>
      
      <div className='dark-background' style={{position:"relative"}} >
            <div className="vortex-small"></div>
            <div className="section-padded-large" style={{minHeight:"60vh"}}>
                <Section  title={<h1 style={{fontSize:isMobile?"2rem":"3rem", fontWeight:"400"}}>
                Poveznica<br/> <a className="font-green" href="https://psihologija.hr/dokumenti/31GKHP_Opatija_2024_KNJIGA_SAZETAKA_31.10.2024.pdf">na knjigu sažetaka</a>
                </h1>}
                content={<p style={{fontSize:"2rem"}}>Kliknite za povratak na <Link className='font-blue'  to="/">naslovnu stranicu</Link></p>}/>
            
            </div>

    </div>
    </>
    
)
}