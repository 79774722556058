import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';

import '../../assets/style.css';
import './style.css';
import logo from "../../assets/logo/logo.svg";
import logo_long from '../../assets/logo/logo_long.svg';
import BasicDropdown from '../dropdown/BasicDropdown';

import {isBrowser, isMobile } from 'react-device-detect';
import { Close, Menu } from '@mui/icons-material';
import MobileNavElement from './MobileNavElement';

export default function Navbar(){

    const [mobileVisible, setMobileVisible] = React.useState(false);

    const elements = [
        {
            text: 'Naslovna',
            link: '/',
            key: 'naslovna'
        },
        {
            text: 'Organizacija',
            link: '/organizacija',
            key: 'organizacija'
        },
        {
            text: 'Oblici sudjelovanja',
            link: '/oblici-sudjelovanja',
            key: 'oblici-sudjelovanja'
        },
        {
            text: 'Program',
            key: 'program',
        },
        {
            text: 'Knjiga sažetaka',
            link: '/knjiga-sazetaka',
            key: 'knjiga-sazetaka'
        },
        {
            text: 'Prijava sudjelovanja',
            link: '/prijava-sudjelovanja',
            key: 'prijava-sudjelovanja'
        },
        {
            text:"Važni datumi",
            link:'/#dates',
            key:'dates'
        },
        {
            text:"Obavijesti",
            link:'/#obavijesti',
            key:'obavijesti'
        }

    ]

    if(isMobile){
        elements[3].elements=[

            {
                text: 'Tematska područja',
                link: "/tematska-podrucja",
                key: "tematska-podrucja"
            }
        ]
    } else {
        elements[3].elements=[
                {
                    text: "Pozvani predavači",
                    link: "/pozvani-predavaci",
                    key: "pozvani-predavaci"
                },
                {
                    text: 'Tematska područja',
                    link: "/tematska-podrucja",
                    key: "tematska-podrucja"
                }
            ]
    }

    return (!isMobile?
        <div id="nav" className="nav-dark">
            <div style={{padding:"20px"}}>
            <div className="flex-row" style={{alignItems:"center", justifyContent:"space-between"}}><Link to="/"><img  src={logo} alt="GKHP31 Logo" className="logo-short"/></Link>
            <div style={{paddingTop: "2vh", paddingBottom:"2vh"}}>
            <div id="nav-list"  className="flex-row">
                
                {elements.map(({text, link, key, elements}, index)=>{
                    return link!=null?
                    <NavLink key={key} to={link}> <div className={"nav-link " + (index%2==0?"nav-link-even":"nav-link-odd")}>{text}</div></NavLink>
                    :<BasicDropdown key={key}  elements={elements} activeElement={<div className={"nav-link " +(index%2==0?"nav-link-even":"nav-link-odd")}>{text}</div>}/>
                    
                    
                })}
            </div>
            </div>
            </div>
            </div>
        </div>
    :<><div id="nav" className="nav-dark">
        <div style={{padding:"20px"}}>
            <div className="flex-row" style={{alignItems:"baseline", justifyContent:"space-between"}}>
                <Link to="/"><img  src={logo} alt="GKHP31 Logo" className="logo-short"/></Link>
                <button onClick={()=>{
                    setMobileVisible(()=>{
                        return mobileVisible?false:true
                    })
                }} id="hanbi">{mobileVisible?<Close style={{height:"2rem", width:"2rem"}}/>:<Menu style={{height:"2rem", width:"2rem"}}/>}</button>
            </div>
        </div>
    </div>
    <div id="mobile-menu" className={mobileVisible?"mobile-menu-visible":""}>
    <MobileNavElement text={<img src={logo_long} alt="GKHP31" className="logo-long"/>}/>
    <div id="mobile-nav-list"  className="flex-column">
                
                {elements.map(({text, link, key, elements}, index)=>{
                  return elements!==undefined? <MobileNavElement text={text} key={index} link={link} elements={elements}/>:<MobileNavElement key={index} text={text} link={link}/>
                    
                })}
            </div>
    </div>
    </>)
}