export const thematix = [
    {
        title:"Uloga i doprinos psihologije u zaštiti mentalnog zdravlja",
        subtitle:"prevencija problema i promicanje mentalnog zdravlja u različitim radnim okruženjima:",
        content:"",
        bullets:{
            'a':"Prepoznavanje / uočavanje potencijalnih izazova za mentalno zdravlje u društvenoj zajednici i\
            pojedinoj organizaciji rada (dijagnostički postupci i mehanizmi njihovog uklanjanja);",
            'b':"Razvoj programa za poslodavce i radnike za smanjenje psihosocijalnog stresa, stresa na radu ili u vezi\
            s radom, s naglaskom na sagorijevanje na radnom mjestu, poticanjem optimalne organizacije posla s\
            ciljem postizanja ravnoteže između poslovnog i privatnog života (posebno ako radnik ima člana\
            obitelji s invaliditetom ili dijete s razvojnim poteškoćama); važnost ranog prepoznavanja problema\
            mentalnog zdravlja i metode rane intervencije na mjestu rada;",
            'c':"Istraživanja o stresu na radu ili u vezi s radom i sindromu sagorijevanja te mentalnim poremećajima i smetnjama povezanim s rizicima radnog mjesta i mjesta rada.;",
            'd':"Identificiranje psihosocijalnih stresora na poslu i kreiranje i provođenje mjera očuvanja mentalnog\
            zdravlja, informiranje o važnosti zdravih uvjeta na mjestu rada te edukacija zaposlenika o utjecaju\
            radne sredine na mentalno zdravlje; razvoj zdrave radne organizacije: promicanje dobrobiti na\
            radnom mjestu; uspostavljanje politika i praksi koje promiču pozitivno radno okruženje; odnosi u\
            društvenoj zajednici i radnom okruženju povezani s mentalnim zdravljem – kako ih poboljšati;",
            'e':"Organizacijski razvoj i upravljanje promjenama: Uključuje komunikaciju s radnicima i podršku u\
            procesima promjene (restrukturiranje, otpuštanja...), upravljanje promjenama organizacijske kulture\
            i krizno komuniciranje;",
            'f':"Istraživanje, kreiranje i provođenje programa i intervencija u cilju prevencije uznemiravanja, spolnog\
            uznemiravanja, svih oblika nasilničkog ponašanja i zlostavljanja na mjestu rada;",
            'g':"Izvori rizika za psihičko zdravlje (diskriminacija i nejednakost, pretjerano radno opterećenje, niska\
                kontrola u vezi posla i nesigurnost posla, nekonformnost radnog okruženja i životnih uvjeta, te drugi\
                rizični čimbenici);",
            'h':"Očuvanje i unapređivanje mentalnog zdravlja, pružanja psihološke potpore i provođenja kriznih\
            intervencija u zanimanjima koja prva dolaze na mjesto događaja (policija, vatrogasci, radnici u hitnim\
            službama i sl.); pojačana skrb za mentalno zdravlje radnika visokorizičnih zanimanja (policija,\
            vatrogasci, radnici u hitnim službama i sl.) provođenjem različitih znanstveno i stručno validiranih\
            psiholoških treninga i programa;",
            'i':"Pojačana skrb za mentalno zdravlje te dodatna edukacija radnika koji brinu o zaštiti vulnerabilnih\
            skupina (žrtava nasilja, ovisnika, korisnika usluga socijalne skrbi, zatvorenika); podrška i rana\
            intervencija za ugrožene osobe."
        }
    },
    {
        title:"Mentalno zdravlje i radna produktivnost u doba digitalne transformacije",
        subtitle:"",
        content:"",
        bullets:{
            'a':"Umjetna inteligencija u radnom okruženju: izazovi i mogućnosti za organizacijsku psihologiju -\
            umjetna inteligencija (UI) u selekciji i upravljanju ljudskim potencijalima; primjena UI u obrazovanju i\
            terapiji – koje su prednosti, a koji nedostaci primjene;",
            'b':"Utjecaj tehnoloških promjena na mentalno zdravlje zaposlenika;",
            'c':"Suočavanje sa sindromom sagorijevanja na poslu: preventivne mjere i intervencije;",
            'd':"Povezanost tehnološkog razvoja sa psihološkom dobrobiti na poslu;",
            'e':"Psihičko zdravlje u uvjetima rada na daljinu (online načinu rada);",
            'f':"Mogućnosti i izazovi online terapije i savjetovanja.",
        }
    },
    {
        title:"Uloga i doprinos psihologa u sustavu odgoja i obrazovanja",
        subtitle:"zaštita psihičkog zdravlja djelatnika, djece i\
        mladih u različitim formalnim i neformalnim okruženjima (predškolske i školske ustanove, visoko-obrazovne\
        ustanove, glazbene škole, sportski klubovi i dr.)",
        content:"",
        bullets:""
    },
    {
        title:"Zaštitni i rizični čimbenici u prilagodbi na bolest",
        subtitle:"poticanje pozitivne promjene potaknute bolestima i\
        stresnim životnim događajima vezanim za zdravlje pojedinca",
        content:"",
        bullets:""
    },
    {
        title:"Izazovi za psihologe",
        subtitle:"",
        content:"",
        bullets:{
            'a':"Uloga i korištenje društvenih mreža u promicanju psihologijskih tema i mentalnog zdravlja -\
            Pouzdane informacije nasuprot lažnim vijestima: kako ih prepoznati na društvenim mrežama?\
            Promocija psihologijskih tema na društvenim mrežama: je li moguća bez kraćih prezentacijskih\
            formata i negativnih komentara? Detoksikacija od društvenih mreža i digitalnih sadržaja: trend ili\
            nužnost? Popularizacija organizacijske psihologije na društvenim mrežama: mogući izazovi i zamke\
            na tom putu; Analiza popularnih psihologijskih stranica i profila na društvenim mrežama;",
            'b':"Psihologija medija, psihološki učinci medija – koliko smo uopće educirani o tome, trebaju li nove\
            generacije (pripadnici alfa generacije) biti o tome bolje informirani unutar obrazovnog sustava?\
            Znamo li postavljati granice u “konzumaciji medija”?",
            'c':"Utjecaj kulturalne raznolikosti na psihološku dobrobit na poslu (na primjer, odnos sa stranim\
                radnicima);",
            'd':"Inkluzivno radno okruženje – pripremaju li poslodavci radno okruženje na različitost? Koja je\
            uloga psihologa u izgradnji inkluzivnog radnog okruženja? Stvaranje inkluzivnog radnog okruženja u\
            kojemu se cijeni različitost, različita mišljenja i vrijednosti, pružaju jednake mogućnosti svim\
            zaposlenicima, te aktivno radi na uklanjanju predrasuda i diskriminacije po bilo kojoj osnovi; zaštita\
            dostojanstva, zabrana diskriminacije i uznemiravanja;",
            'e':"Izazovi mentalnoga zdravlja u sportu - Poremećaji hranjenja u sportu; perfekcionizam i sagorijevanje\
            kod sportaša; Natjecateljska anksioznost; Obilježja i posljedice nasilja u sportskom okruženju;",
            'f':"Uloga psihologa u (zdravstvenom) turizmu;",
            'g':"Povezanost psihičkog zdravlja s promicanjem i zaštitom ljudskih prava.",
        }
    },
    {
        title:"Uloga i doprinos psihologije u interdisciplinarnoj suradnji",
        subtitle:"psiholozi kao dio interdisciplinarnih timova",
        content:"",
        bullets:""
    },
]