import * as React from 'react';
import { randomId } from './util';

export default function CheckBox({label, checked=undefined, onChange=undefined, fontSize=undefined, fontWeight="400"}){
    let id=randomId()

    return (
        <div className="checkbox-element">
            <span><input checked={checked!=undefined?checked:null} onChange={onChange} className="checkbox-box" type="checkbox" id={id}/></span>
            <span><label style={{fontSize:fontSize, fontWeight:fontWeight}} className="checkbox-label" htmlFor={id}>{label}</label></span>
        </div>
    )
}