import * as React from 'react';

import './style.css';
import { Link } from 'react-router-dom';

export default function ImageLink({width='100%',image=null, name=null, description=null, link=null, button=null, title=null}){

    return(
        <div className="flex-column image-link" style={{alignItems:"center", justifyContent:"start", width:width}}>
            <div className="image-circle">
                <img src={image}></img>
            </div>
            <div className="name-text">
                <p>{name}, {title}</p>
            </div>
            <div className="description-text">
                <p>{description}</p>
            </div>
            {button?<button className="background-transparent" ><Link className="image-link-button" to={link}>PROČITAJTE</Link></button>:<></>}
        </div>
    )
}