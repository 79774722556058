import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_org.css'
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';

export default function NotFound(){

    return (
        <>
        <Navbar/>
        <div className='dark-background' style={{position:"relative"}} >
            <div className="vortex-small"></div>
            <div className="section-padded-large" style={{minHeight:"60vh"}}>
                <Section  title={<h1 style={{fontSize:"3rem", fontWeight:"400"}}>Tražena stranica <span className="font-gradient-large">ne postoji</span>.</h1>}/>
            
            </div>
    <div className="background-overlay">
        <Footer/>
    </div>
    </div>
    </>
    
)
}