import * as React from "react";

import { useNavigate } from "react-router-dom";

import { api } from "../environment";

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    const [authData, setAuth] = React.useState(localStorage.getItem("site")||"")
    const navigate = useNavigate();
    const login = async (data) => {

    try{
        const response = await fetch(api+"/login",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
        })
        const res = await response.json();
        if(res){
            setAuth(res.token);
            localStorage.setItem('site', res.token);
            navigate("/crs");
            return;
        }

    } catch (err) {
        console.log(err);
    }
    }

    const logout = () => {
        setAuth("")
        localStorage.setItem("site", "")
        navigate("/");
      };

  return <AuthContext.Provider value={{authData, login, logout}}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return React.useContext(AuthContext);
};
