import * as React from 'react';

import { api } from '../../environment';
import Table from '../../components/table/Table';
import CheckBox from '../../components/formElements/CheckBox';
import { RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {utils, write}from 'xlsx';
import { saveAs } from 'file-saver';

export default function ApplicationsView(){
    const [dataRows, setDataRows] = React.useState([]);
    const [noHotelRows, setNoHotelRows] = React.useState([]);
    const [filterVars, setFilterVars] = React.useState({booking:false,confirmed:false, rejected:false, waiting:false});

    const orderData = (data)=>{
        return data.map((row, index) =>{
            row['order'] = index+1;
            return  row;
        })
    }

    const defineActions = (data)=>{
        return data.map((row, index)=>{
             row['action'] = <Link to={"/crs/pregled-prijava-sudjelovanja/"+row['id']}><RemoveRedEye/></Link>
             return row;
        })
    };

    

    const getData = (body) => {
        let call = fetch(api+"/get_participants",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
              
              body:JSON.stringify({token:localStorage.getItem('site'), ...body})
            })
            try{
        return call.then(response=>response.json())
            } catch (err){
                console.log(err)
                return [];
            }
    }

    const toggleFilterVar = (name) =>{
        setFilterVars((prevState)=>{
            let newState={};
            newState[name] = !filterVars[name];
            
            return {
                ...prevState,
                ...newState
            }
        })
    }

    const handleFilterChange = (name)=>{
        toggleFilterVar(name);
        getData({criteria:filterVars}).then(
            data=>setDataRows(orderData(data))
        )
    }

    const header = [{name:"order", label:"#", width:"10%"},{name:"first_name", label:"ime", width:"25%"}, {name:"last_name", label:"prezime", width:"25%"}, {name:"e_mail", label:"e-mail", width:"30%"}, {name:"status", label:"Status prijave", width:"10%"}, {name:"action", label:"akcija"}, ]
    const filters = [
        <CheckBox label="S bookingom hotela" checked={filterVars['booking']} 
        onChange={()=>{
            handleFilterChange('booking');
        }}/>,
        <CheckBox label="Potvrđene prijave" checked={filterVars['confirmed']} onChange={()=>{
            handleFilterChange('confirmed');
        }}/>,
        <CheckBox label="Odbijene prijave" checked={filterVars['rejected']} onChange={()=>{
            handleFilterChange('rejected');
        }}/>,
        <CheckBox label="Prijave u čekanju" checked={filterVars['waiting']} onChange={()=>{
            handleFilterChange('waiting');
        }}/>
    ]

    const valueMapper = (name, value) => {
        let mapping = {
            "payment_type":{"1":"Rana", "2":"Srednja", "3":"Kasna", "4":"Posebna", "5":"Odbor"},
            "hpd_membership":{"1":"Da", "2":"Ne"},
            "need_booking":{"1":"Da", "0":"Ne"},
            "payment_way":{"1":"Bankovna transakcija", "2":"Kreditna kartica"},
            "hotel":{"1":"Hotel Ambasador", "2":"Hotel Imperial", "3":"Hotel Admiral", "4":"Hotel Palace Bellevue", "5":"Hotel Istra"},
            "date_arrival":{"6":"6.11.2024.", "7":"7.11.2024", "8":"8.11.2024"},
            "date_departure":{ "7":"7.11.2024", "8":"8.11.2024", "9":"9.11.2024"},
            "status":{"0":"Čeka pregled", "1":"Odobren", "-1":"Odbijen"}
        }
        if(mapping[name]==undefined) return value;
        return mapping[name][value]
    }

    const mapValues = (data) =>{
        return data.map((row, index)=>{
            for(let name in row){
                row[name] = valueMapper(name,row[name])
            }
            return row
        })
    }
    

    React.useEffect(()=>{
        getData().then(
            data=>setDataRows(mapValues(defineActions(orderData(data))))
        )
    }, [])

    const filterData = (data) =>{
        return data.filter((element)=> element.status==="Odobren")
    }

    const exportToExcel = (data, fileName) => {
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `${fileName}.xlsx`);
      };

    const mutateDataForExport = (data) =>{
        console.log(data)
        console.log(data)
        const mapping = {
            "payment_type":"Vrsta kotizacije",
            "price": "Iznos kotizacije",
            "first_name":"Ime",
            "last_name":"Prezime",
            "address":"Adresa",
            "city":"grad",
            "state":"Država",
            "oib":"OIB",
            "phone":"Telefon",
            "mobile":"Mobitel",
            "e_mail":"E-Mail",
            "hpd_membership":"Član HPD-a",
            "hpk_number":"HPK-članski broj",
            "need_booking":"Želim rezervirati hotelski smještaj",
            
        }

        const hotelMapping={
            "hotel":"Odabir hotela",
            "person_count":"Broj osoba u sobi",
            "date_arrival":"Datum dolaska",
            "date_departure":"Datum odlaska",
            "name_roommate":"Ime druge osobe u sobi",
            "note":"Dodatne napomene",
        }

        const payerMapping={
            "name":"Tvrtka",
            "address":"Adresa platitelja",
            "oib":"OIB tvrtke",
            "email":"Mail platitelja",
            "payment_way":"Način plaćanja"
        }

        

        return data.map((row, index)=>{
            let booking = row.booking!=null?row.booking:{hotel:'', person_count:'', date_arrival:'', date_departure:'', note:'', name_roommate:''};
            let payment = row.payment!=null?row.payment:{name:'', address:'', oib:'', email:'', payment_way:''};
            let mutatedRow = {}
            for(let name in mapping){
                mutatedRow[mapping[name]] = row[name]
            }
            for(let name in hotelMapping){
                mutatedRow[hotelMapping[name]] = valueMapper(name,booking[name]);
            }
            if(payment!=null){
            for(let name in payerMapping){
                    mutatedRow[payerMapping[name]] = valueMapper(name, payment[name]);

            }
            
        }
        mutatedRow["Status prijave"] = row["status"]
            console.log(row);
            return mutatedRow
        })


    }

    return (
        <>
        <h1 style={{marginBottom:"2rem"}}>Pregled prijava sudjelovanja</h1>
        <div className="input-field" style={{marginTop:"0.5rem"}}>
        <Table header={header} dataSource={dataRows!=undefined?dataRows:[]} layout="fixed"/>
        <button className="modal-button" style={{margin:"1rem"}}  onClick={()=>{exportToExcel(mutateDataForExport(dataRows), 'sudionici')}}>Izvoz u Excel</button>
        </div>
        </>
    )
}