import * as React from 'react';

import '../assets/style.css';
import Tabular from '../components/formElements/Tabular';
import { isEmpty } from '../components/formElements/validators';

export default function Test(){

    const [dataSource, setDataSource] = React.useState([]);

    const header = [{name:"order", label:"#"}, 
    {name:"first_name", label:"Ime", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"}, 
    {name:"last_name", label:"Prezime", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"}, 
    {name:"institution", label:"Institucija", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"title", label:"Titula", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}},
    {name:"city", label:"Grad", formField: {necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"state", label:"Država", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"actions", label:"Akcija", position:"sticky"}];


    return (
        <div style={{width:"50%"}}><Tabular field={{name:'author', type:'tabular', dataSource:dataSource, setDataSource:setDataSource, header:header, label:'Popis autora/aktivnih sudionika', necessary:true, validators:[], layoutStyle:{flex:"1 1 100%"}}} dataSource={dataSource} setDataSource={setDataSource}/></div>
    )
}