import * as React from 'react';

import './style.css';
export default function Modal({title=null, content=null, buttons=[], hidden=true}){

    return (
        <div  className="modal-wrap" style={{display:hidden?'none':'block'}}>
            <div className="modal">
                <div className="modal-cntnt">
                    <div className="modal-top">
                        <h3>{title}</h3></div>
                        <div className="content-body">
                        {content}
                        </div>
                        </div>
                        {buttons.length>0?
                        <div className="modal-actions">
                        {buttons.map((button, index)=>{
                            return <div key={index}>{button}</div>
                        })}</div>:<></>
                            
                    }
                        
                        </div></div>
    )
}