import * as React from 'react';

import '../../assets/style.css';
import Footer from '../../components/footer/Footer';
import CheckBox from '../../components/formElements/CheckBox';
import { makeFormControls, validateForm } from '../../components/formElements/util';
import { isEmail, isEmpty } from '../../components/formElements/validators';
import Form from '../../components/formElements/Form';
import Table from '../../components/table/Table';
import Modal from '../../components/modal/Modal';

import axios from 'axios';
import Navbar from '../../components/nav/Navbar';

import { api } from '../../environment';
import PresentationForm from '../../components/forms/PresentationForm';

import {isBrowser, isMobile } from 'react-device-detect';

export default function AbstractApp(){

    return (
        <>
        <Navbar/>

        <div className="flex-column" style={{paddingTop:"18vh",gap:"4rem", width:"100%"}}>
        <div><h1 className="section-title-font" style={{color:"#223d69", fontWeight:"400", fontSize:isMobile?"2rem":"3.5rem", textAlign:"center"}}>Prijave sažetaka su zatvorene</h1></div>
            <div style={{width:isMobile?"95%":"50%", margin:"auto", minHeight:"35vh"}}>
        </div>
        <div  style={{color:"white"}} className="background-overlay">
                <Footer/>
            </div>
        </div>
        </>
    )
}