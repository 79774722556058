import * as React from 'react';


import './style.css';

import Form from '../formElements/Form';

export default function LoginForm({formData, formControls, setFormControls}){

    

    return(
        <div className="login-form flex-column" style={{gap:"1rem"}}>
        <Form formData={formData} formControls={formControls} setFormControls={setFormControls}/>
        </div>
    )
}