import * as React from 'react';

import './style.css'

export default function CounterElement({label=undefined, value=0}){

    return (
        <div className="flex-column counter">
            <div className="counter-value">
                {value}
            </div>
            <div className="counter-label">
                {label}
            </div>
        </div>
    )
}