import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_org.css'
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';

import {isBrowser, isMobile } from 'react-device-detect';

export default function Organization(){

    const program_commitee_1=[
        "Mladenka Tkalčić – predsjednica PO",
        "Irena Miletić – tajnica PO",
        "Petra Anić",
        "Toni Babarović",
        "Anamarija Bogović",
        "Slavka Galić",
        "Zrinka Greblo Jurakić",
        "Slobodan Jović",
        "Barbara Kalebić Maglica"
    ]

    const program_commitee_2=[
        "Miljana Kukić",
        "Damir Lučanin",
        "Orjana Marušić Štimac",
        "Alessandra Pokrajac-Bulian",
        "Silvija Ručević",
        "Nelija Rudolfi",
        "Mitja Ružojčić",
        "Ana Slišković",
        "Zoran Sušanj",
        "Sanja Tatalović Vorkapić"
    ]

    const organization_commitee = [
        "Hrvoje Gligora - predsjednik OO",
        "Nada Kegalj",
        "Josip Lopižić",
        "Tanja Tuhtan-Maras",
        "Natalija Marković",
        "Ljiljana Mikuš",
        "Borka Ogrizović",
        "Linda Kolić-Sobol",
    ]

    return (
        <>
        <Navbar/>
        <div className='dark-background' style={{position:"relative"}} >
            <div className="vortex-small"></div>
            <div className="section-padded-standard" style={{minHeight:"60vh"}}>
            <Section content={
                <div className={isMobile?"flex-column":"flex-row"} style={{justifyContent:"center", gap:"12%", height:"100%"}}>
                    <div className='flex-column' style={{ alignItems:"center"}}>
                    <h2 className="font-gradient-large" style={{fontSize:isMobile?"1.5rem":"2.2rem", marginBottom:"2rem", fontWeight:"400"}}>Programski odbor</h2>
                <div className="flex-row comm-wrapp proglist" style={{width:'fit-content', padding:isMobile?"2rem":"4rem"}}>
                    <ul id="prog-1">
                        {
                            program_commitee_1.map((element, i)=>{
                                return <li className='comm-member' key={i}>{element}</li>
                            })
                        }
                    </ul>
                    <ul id="prog-2">
                        {
                            program_commitee_2.map((element, i)=>{
                                return <li className='comm-member' key={i}>{element}</li>
                            })
                        }
                    </ul>
                </div>
                </div>
                
                <div className='flex-column' style={{ alignItems:"center", width:isMobile?"100%":"30%", height:"100%"}}>
                    <h2 className="font-gradient-large" style={{fontSize:isMobile?"1.5rem":"2.2rem", marginBottom:"2rem", fontWeight:"400"}}>Organizacijski odbor</h2>
                <div className="flex-row comm-wrapp orglist" style={{width:'100%', height:"100%", paddingTop:"7rem", paddingBottom:"7rem", justifyContent:"center", alignItems:"center"}}>
                    
                    <ul id="org-ul">
                    {
                            organization_commitee.map((element, i)=>{
                                return <li className='comm-member' key={i}>{element}</li>
                            })
                        }
                    </ul>
                    </div>
                </div>
                </div>
            }/>
            </div>
    <div className="background-overlay">
        <Footer/>
    </div>
    </div>
    </>
    
)
}