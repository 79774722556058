import * as React from 'react';
import { makeFieldChangeHandle, makeValidateField, randomId } from './util';

export default function TextArea({field, formControls, setFormControls, className=undefined, style=undefined, disabled=undefined}){
    let id = randomId();

    const [validity, setValidity] = React.useState({valid:true, warning:""})


    let handleFieldChange = undefined;
    if(formControls!=undefined){
        const validateField = field.necessary?makeValidateField(field, setValidity, formControls, setFormControls, disabled=false):undefined;
       handleFieldChange = makeFieldChangeHandle(field, setFormControls, validateField);
    }

    return(
        <div className={"input-field label-visible " + (className!=undefined?className:"")} style={style}>
            <label htmlFor={id}>{field.label}</label>
            <textarea disabled={disabled} value={formControls!=undefined?formControls[field.name].value:""} onChange={handleFieldChange!=undefined?handleFieldChange:null} id={id} placeholder={field.placeholder!=undefined?field.placeholder:field.label}/>
            {!formControls[field.name].validity.valid?<div className="warning">{formControls[field.name].validity.warning}</div>:<></>}
        </div>
    )
}