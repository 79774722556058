import * as React from 'react';
import { NavLink } from 'react-router-dom';

export default function MobileNavElement({text, link, elements=undefined}){
    const [open, setOpen] = React.useState(false);
    console.log("isopen", open)

    return(
        <div className="mobile-nav-element">
            {elements===undefined?<NavLink to={link}><div className="mobile-nav-link">{text}</div></NavLink>:
            <div className='mobile-hover-element'>
                <div onClick={()=>{
                    setOpen((prevState)=>{
                        return !prevState;
                    })
                }} className='mobile-nav-link'>{text}</div>
                <div className={"mobile-hover-container "+ (open?'mobile-hover-container-visible':'mobile-hover-container-hidden')}>
                {elements.map((element, index)=>{
                    return <NavLink key={index} to={element.link}><div className={"mobile-nav-link mobile-hover-element"}>{element.text}</div></NavLink>
                })}
                </div>
            </div>}
        </div>
    )
}