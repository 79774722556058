import * as React from 'react';
import PersonImageNormal from '../../components/layouts/PersonImageNormal';
import {useParams } from 'react-router-dom';

import { greetings } from '../../data/greetings';

import '../../assets/style_greeting.css';
import '../../assets/style.css';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/nav/Navbar';
import {isBrowser, isMobile } from 'react-device-detect';
import PersonImageMobile from '../../components/layouts/PersonImageMobile';


export default function Greetings(){
    const {greeting} = useParams()
    const person = greetings[greeting]
    return (
        <>
        <Navbar/>
    <div className="flex-column centered" style={{minHeight:"60vh", alignItems:"center", paddingTop:isMobile?"12vh":"18vh"}}>
        <h1 className="font-gradient-large" style={{fontSize:isMobile?"1.5rem":"2.5rem", fontWeight:"400", textAlign:"center"}}>{person.section_title}</h1>
    <div id="greeting">
        {isMobile?<PersonImageMobile title={person.title} name={person.name} description={person.description} image={person.image_vert} text={person.text}
        />:<PersonImageNormal title={person.title} name={person.name} description={person.description} image={person.image_vert} text={person.text}/>}
    </div>
    </div>
    <div className='background-overlay' style={{width:"100%"}}>
    <Footer/>
    </div>
    
    </>)
}