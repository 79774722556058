import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import CRS from "../controllers/Crs/CRS";

const PrivateRoute = () => {
  const auth = useAuth();
  const validateAuth = () => {

  }

  if (auth.authData==""||auth.authData==null) return <Navigate to="/login" />;
  return <CRS/>
};

export default PrivateRoute;
