import * as React from 'react';

import './style.css';
import { Link } from 'react-router-dom';

export default function BasicDropdown({elements, activeElement}){

    return (
        <div className="dropdown-wrap">
            <div className="active-element">{activeElement}</div>
        <div className="dropdown-transparent">
            <div className="dropdown">
                {
                    elements.map(({text, link, key}) =>{
                        return (<Link className="dropdown-link"  key={key}  to={link}>
                            <div className="dropdown-element">
                                {text}
                            </div>
                                </Link>)
                    })
                }
            </div>
        </div>
        </div>
    )

}