import * as React from 'react';

import './style.css';

export default function PersonImageMobile({image, name, description, text, title=null}){

    return (
            
        <div className="flex-column" style={{width:"100%", margin:"auto"}}>
        <div className="flex-column" style={{paddingBottom:"5vh"}}>
            <div className="person-image-vert">
                <img style={{width:"60%"}} src={image}></img>
            </div>
            <div>
                <span className="font-gradient-large" style={{fontSize:"1.6rem"}}>{name}, {title}</span>
            </div>
            <div>
            <span className="person-image-description" style={{fontSize:"1.2rem"}}>{description}</span>
                
            </div>
        </div>
        <div className="person-image-text" style={{maxWidth:"100%", height:"40vh", overflowY:"scroll", position:"relative"}}>
            <p>{text}</p>
        </div>
        </div>
    )
}