export const participation_forms = [
    {
        title:"Usmeno izlaganje",
        text:"Izlaganje pojedinačnog istraživanja ili teme u trajanju od 10 minuta, nakon kojeg slijedi 5-minutna\
        rasprava. Sažetak empirijskog rada treba sadržavati jasno izloženi cilj i/ili probleme istraživanja,\
        metodologiju te glavne rezultate s naznakama njihove interpretacije i glavnih zaključaka. Omjer ovih dijelova sažetka\
        treba biti uravnotežen, a stil pisanja jasan i precizan. U sažetku se ne navode referentni izvori, a rezultate statističkih\
        analiza je poželjno opisno navesti. Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi.\n\n\
        Povratnu informaciju o odluci Programskog odbora do 15.10.2024. Sudionici čija je prijava prihvaćena dužni su se pravovremeno\
        registrirati kako bi njihova aktivnost bila\
        uključena u program skupa i knjigu sažetaka."

    },
    {
        title:"Poster izlaganje",
        text:"Prezentacije različitih tema, koje omogućuju intenzivniju interakciju autora s drugim sudionicima\
        tijekom trajanja vođene poster-sekcije. Dimenzije postera ne smiju prelaziti B1 (707x1000 mm).\
        Ako poster prikazuje rezultate empirijskog istraživanja, struktura i sadržaj sažetka trebaju biti kao i kod usmenog\
        izlaganja (v. „Usmeno izlaganje“).Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi.\n\n\
        Povratnu informaciju o odluci Programskog odbora o (ne)prihvaćanju prijave sudionici će dobiti do 15.10.2024.\
        Sudionici čija je prijava prihvaćena dužni su se pravovremeno registrirati kako bi njihova aktivnost bila\
        uključena u program skupa i knjigu sažetaka."

    },

    {
        title:"Simpozij",
        text:"Osim voditelja, koji organizira i prijavljuje simpozij, za prijavu simpozija potrebno je najmanje 4, a najviše\
        6 izlaganja različitih autora koji se bave zajedničkom temom ili nizom tematski povezanih istraživanja. Trajanje\
        izlaganja u okviru simpozija jednako je kao i kod drugih usmenih izlaganja. Ukupno trajanje simpozija može biti od 60\
        do 90 minuta. Sažetak simpozija kao i sažetke svih izlaganja u sklopu simpozija prijavljuje voditelj simpozija.\n\n\
        Voditelj u prijavi treba navesti (a) naslov simpozija, (b) ime i prezime, instituciju, grad, državu i e-mail voditelja, (c)\
        sažetak simpozija koji sadrži opis i obrazloženje teme simpozija, uključujući pregled tema pojedinih izlaganja u\
        opsegu 200-400 riječi, (d) 3-5 ključnih riječi, (e) popis naslova svih izlaganja u okviru simpozija (s imenom i\
        prezimenom autora) onim slijedom kojim bi trebali biti izlagani na simpoziju. Voditelj simpozija je odgovoran za\
        istovjetnost navoda u ovom sažetku s naslovima i imenima autora u sažecima pojedinih izlaganja u simpoziju kao i za\
        obavještavanje sudionika simpozija o odluci Programskog odbora o (ne)prihvaćanju simpozija. U slučaju\
        nepravovremenih ili nepotpunih prijava voditeljskog i/ili individualnih sažetaka, Programski odbor neće razmatrati\
        prijavu simpozija. U slučaju da simpozij ne bude prihvaćen, Programski odbor će autorima individualnih izlaganja koja\
        dobiju pozitivnu recenziju ponuditi mogućnost sudjelovanja na skupu izvan planiranog simpozija.\n\n\
        Postupak izrade sažetka pojedinog izlaganja u okviru simpozija jednak je kao kod drugih usmenih izlaganja (v.\
        „Usmeno izlaganje“). Odgovornost je autora da svoj sažetak pripreme u skladu sa svim relevantnim uputama i\
        pravovremeno ga dostave voditelju simpozija, koji će njihov sažetak prijaviti, kao i da se na vrijeme sami registriraju\
        kao sudionici. Pojedina izlaganja bit će recenzirana zasebno i, u slučaju negativne recenzije zbog koje se ukupni broj\
        izlaganja u simpoziju smanjuje ispod ranije definiranog minimuma, simpozij u okviru kojeg je konkretno izlaganje\
        planirano neće biti prihvaćen. Autorima pozitivno recenziranih pojedinih izlaganja bit će ponuđena mogućnost\
        sudjelovanja na skupu izvan simpozija."

    },

    {
        title:"Okrugli stol",
        text:"Rasprava o relevantnoj stručnoj ili istraživačkoj temi u trajanju od 30, 60 ili 90 minuta, koju organizira i\
        prijavljuje voditelj. Sažetak treba sadržavati opis i obrazloženje teme okruglog stola, uključujući doprinose pojedinih\
        sudionika (200–400 riječi), 3-5 ključnih riječi, te planirano trajanje okruglog stola (30, 60 ili 90 minuta)."

    },
    {
        title:"Radionica",
        text:"Aktivnost koju organizira i vodi prijavljeni voditelj u trajanju od 30, 60 ili 90 minuta. Sažetak treba\
        sadržavati kratki prikaz cilja, očekivanih ishoda za polaznike radionice, planiranih aktivnosti, predloženo trajanje\
        radionice (30, 60 ili 90 minuta) te napomenu o eventualnom ograničenju broja sudionika i drugim mogućim\
        posebnim zahtjevima. Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi."

    },

    {
        title:"Stručni domjenak",
        text:"Druženje sudionika koji dijele zajedničke interese. Sažetak treba sadržavati cilj i obrazloženje\
        planiranog druženja, predviđeni broj sudionika i trajanje, uz eventualne napomene glede posebnih dodatnih zahtjeva\
        ili ograničenja. Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi."

    },

    {
        title:"Sastanak stručne sekcije/radne skupine",
        text:"Za vrijeme skupa može se organizirati sastanak neke od stručnih sekcija\
        HPD-a ili sastanak pojedinih radnih skupina itd. Sažetak treba sadržavati cilj i obrazloženje planiranog sastanka,\
        predviđeni broj sudionika i trajanje sastanka (30 ili 60 minuta), uz eventualne napomene glede posebnih dodatnih\
        zahtjeva ili ograničenja. Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi."

    },

]

export const participation_additional = [

    {
        title:"Predstavljanje knjiga, psihologijskih mjernih instrumenata te drugih publikacija i uradaka",
        text:"Na skupu se može\
        organizirati predstavljanje knjiga, psihologijskih mjernih instrumenata te drugih publikacija i uradaka. U prijavi\
        aktivnosti treba navesti osnovne podatke o organizatoru i promotoru, popis drugih aktivnih sudionika sa svim\
        osnovnim podacima kao i za organizatora te trajanje predstavljanja (15, 30 ili 60 minuta), a u sažetku navesti detaljne\
        podatke o publikaciji ili mjernom instrumentu koji se predstavlja te eventualne dodatne napomene Programskom\
        odboru. Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi."

    },

    {
        title:'„Izvan okvira"',
        text:'kratak inspirativan i dinamičan prikaz neke aktualne teme vezane uz područje psihologije - prikaz\
        korištenja novih, inovativnih i kreativnih tehnika u radu i dr. Trajanje svakog od ovih oblika sudjelovanja je od 5 do 15\
        minuta. Prijava ovih oblika aktivnosti se mora napraviti putem online obrasca do roka za prijavu sažetaka. Sažetak\
        treba sadržavati naslov i jasan opis planirane aktivnosti, kao i podatke o eventualnim drugim sudionicima te\
        eventualne dodatne napomene Programskom odboru. Tekst sažetka treba biti dugačak 200-400 riječi te treba\
        navesti 3-5 ključnih riječi.'

    },

    {
        title:"Psiholozi kao kreativci",
        text:"izložbe, kratke literarne, dramske/komične i glazbene izvedbe, kvizovi i druge kreativne i\
        umjetničke aktivnosti, u dostupnim prostorima hotela. Sažetak treba sadržavati naslov i jasan opis planirane\
        aktivnosti, kao i podatke o eventualnim drugim sudionicima te eventualne dodatne napomene Programskom\
        odboru. Tekst sažetka treba biti dugačak 200-400 riječi te treba navesti 3-5 ključnih riječi."

    },

    {
        title:" Ostale aktivnosti",
        text:"Na GKHP moguće je prijaviti i druge oblike sudjelovanja, koji nisu već navedeni u online obrascu\
        prijave. Kao i kod drugih oblika sudjelovanja, prijava se mora napraviti putem online obrasca do roka za prijavu\
        sažetaka. Sažetak treba sadržavati naslov i jasan opis planirane aktivnosti, kao i podatke o drugim sudionicima te\
        eventualne dodatne napomene Programskom odboru. Tekst sažetka treba biti dugačak 200-400 riječi te treba\
        navesti 3-5 ključnih riječi."

    },
]