import * as React from 'react';

import './style.css';
export default function Circles(){
    
    return (
        <div id="poster-circles">
            <div className="flex-row">
                <div className="circle">

                </div>
                <div className="circle">

                </div>
                <div className="circle">

                </div>
            </div>
            <div className="flex-row">
                <div className="circle">

                </div>
                <div className="circle">

                </div>
            </div>
            <div className="flex-row" style={{justifyContent:'space-between'}}>
                <div className="circle">

                </div>
                <div className="circle circle-green">

                </div>
            </div>
        </div>
    )
}