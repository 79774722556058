import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';

import { participation_forms } from '../../data/participation_forms';
import { participation_additional } from '../../data/participation_forms';
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';
import {isBrowser, isMobile } from 'react-device-detect';

export default function ParticipationForms(){
    return (
        <>
        <Navbar/>
        <div className="dark-background flex-column" style={{height:"100%", position:"relative"}}>
            <div className="vortex-small"></div>
            <div className="flex-column centered" style={{minHeight:"100vh", alignItems:"center"}}>
                <h1 className="font-gradient-large" style={{paddingTop:"15vh", fontWeight:"400", fontSize:isMobile?"2rem":"4rem"}}>Oblici sudjelovanja</h1>
                <div style={{width:isMobile?"95%":"80%", position:"relative"}}>
                <div><h2 style={{fontSize:isMobile?"1rem":"2rem",fontWeight:"400", paddingLeft:"7rem"}}><span>Standardni oblici sudjelovanja</span></h2>
                {
                    participation_forms.map(({title, text}, index)=>{
                      return <Section key={index} style={{marginBottom:"3rem"}} className={"section-overlay-transparent " + (index%2==0?"section-half-bordered-top":"section-half-bordered-bottom")} title={<span><h2  className="font-gradient-large" style={{margin:"0", fontSize:isMobile?"1.5rem":"3rem", fontWeight:"400"}}>{title}</h2></span>} content={
                        <div style={{padding:isMobile?"0 1rem 1rem 1rem":"0 7rem 7rem 7rem"}}>
                            <p style={{fontSize:isMobile?"1rem":"1.5rem", whiteSpace:"pre-line", lineHeight:"1.5", textAlign:"left"}}>
                                {text}
                            </p>

                        </div>
                      }/>  
                    })
                }
            </div>
            
            <div><h2 style={{fontSize:isMobile?"1rem":"2rem",fontWeight:"400", textAlign:"right", paddingRight:"7rem", marginTop:"4rem"}}>Dodatne aktivnosti</h2>
                {
                    participation_additional.map(({title, text}, index)=>{
                        return <Section style={{marginBottom:"3rem"}} className={"section-overlay-transparent " + (index%2==0?"section-half-bordered-top":"section-half-bordered-bottom")} key={index} title={<span><h2  className="font-gradient-large" style={{margin:"0", fontSize:isMobile?"1.5rem":"3rem", fontWeight:"400"}}>{title}</h2></span>} content={
                            <div style={{padding:isMobile?"0 1rem 1rem 1rem":"0 7rem 7rem 7rem"}}>
                              <p style={{fontSize:isMobile?"1rem":"1.5rem", whiteSpace:"pre-line", lineHeight:"1.5", textAlign:"left"}}>
                                  {text}
                              </p>
  
                          </div>
                        }/>  
                      })
                }
            
            </div>


            
            </div>
            </div>
        <div className="background-overlay">
        <Footer/>
        </div>
        </div>
        
    
    </>

    )
}