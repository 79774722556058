import * as React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ImageLink from '../layouts/ImageLink';
import {isBrowser, isMobile } from 'react-device-detect';

import './style.css';
export default function Slider({elements=[], number_visible=1, gap=20, offset=1, scale=100}){

    const [position, setPosition] = React.useState(0);
    const one_width = Math.floor(scale/elements.length);

    const sliderStyle={
        width:(one_width*elements.length+gap).toString()+"vmax",
        gap:gap,
        left: position.toString()+"vmax",
    }
    
    return (
        <div className="flex-row" style={{justifyContent:"space-between", position:"relative",aligItems:"center", maxWidth:"100vw", paddingBottom:"3vh"}}>
            <button disabled={position>=0} onClick={()=>{
                setPosition(position+one_width)
            }} className="slider-button"><ArrowLeftIcon className="slider-arrow"></ArrowLeftIcon></button>
            <div className="slider-wrapper" >
            <div className="slider-body" style={sliderStyle}>
                {elements.map((element, index)=>{
                    return <div key={index} style={{position:"relative", width:one_width.toString()+"vmax", display:"flex", flexDirection:"column", justifyContent:"start"}}>{element}</div>
                })
                }
            </div>
            </div>
            <button disabled={position<=-1*one_width*offset*number_visible}onClick={()=>{
                setPosition(position-one_width)
            }} className="slider-button"><ArrowRightIcon className="slider-arrow" ></ArrowRightIcon></button>
        </div>
    )
}