import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';

import '../../assets/style.css';
import './style.css';

export default function Section({className=null, title=null, style=null, content=null, contentStyle={height:"20%", textAlign:"center"}}){

    return (
        <div style={style} className={"section flex-column flex-centered "+className}>
            <div className='section-title'>
                {title!=null?title:<></>}
            </div>
            <div style={contentStyle}>
                {content!=null?content:<></>}
            </div>
        </div>
    )
}