import * as React from 'react';

import './style.css';

export default function PersonImageTopCvAbstractMobile({image, name, description, text, lecture_text, lecture_title, title=null, lang='hr'}){
    const [selection, setSelection] = React.useState(0);

    return (
        <div className="flex-column flex-centered" style={{alignItems:"center", paddingTop:"2%"}}>
        <div className="flex-column" style={{width:"95%", gap:"1rem", justifyContent:"space-between", margin:"auto", textAlign:"left", paddingBottom:"2rem"}}>
            <div className='flex-column' style={{justifyContent:"space-between"}}>
            <div>
                <div className="font-gradient-large" style={{fontSize:"1.2rem"}}>{name}, {title}</div>
                <div className="person-image-description" style={{fontSize:"1rem"}}>{description}</div>
            </div>
            </div>
            <div className="flex-column flex-centered">
                <div className="person-image-vert" style={{textAlign:'center'}}>
                    <img style={{height:"35vh"}}src={image}></img>
                </div>
                <div className="flex-row" style={{color:"black !important", fontSize:"1.5rem"}}><div className={"choice-item "+ (selection==0?"choice-item-selected":"")} onClick={()=>{setSelection(0)}}>{lang=="hr"?"Životopis":"CV"}</div><div onClick={()=>{setSelection(1)}} className={"choice-item "+ (selection==1?"choice-item-selected":"")}>{lang=="hr"?"Sažetak":"Abstract"}</div></div>
            <div style={{textAlign:"center", paddingLeft:"6rem", paddingBottom:"3rem"}}>
            </div>

            <p style={{color:"black", fontSize:"1rem"}}>{lang=="hr"?"Naslov predavanja:":"Lecture title:"}</p>
            <p className="lecture-title">{lecture_title}</p>
            </div>
        </div>
        <div className="person-image-text" style={{maxWidth:"95%", height: "30vh", overflowY:"scroll"}}>
            <p hidden={selection!=0} style={{textAlign:"center"}}><span style={{fontWeight:800}}>{name+(text[0]!=','?' ':'')}</span>{text}</p>
            <p hidden={selection!=1} style={{textAlign:"center"}}>{lecture_text}</p>
        </div>
        </div>
    )
}