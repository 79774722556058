export function isEmpty(value){
    if(value.length>0){
        return {valid:true, necessary:true, formNecessary:true, warning:""}
    }
    return {valid:false, necessary:true, formNecessary:true, warning:"Polje ne smije biti prazno!"};
}
export function isEmail(value){
    const email_re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(email_re.test(value)){
        return {valid:true, necessary:true, formNecessary:true, warning:""}
    }
    return {valid: false, necessary:true, formNecessary:true, warning:"E-Mail nije ispravnog formata!"}
}
export function isLength(value, minLength, maxLength){}
export function isOIB(value){}

export function isTable(value){
    if(value.length===0){
        return {valid: false, necessary:true, formNecessary:true, warning:'Tablica ne smije biti prazna!'}
    }
    return {valid:true, necessary:true, formNecessary:true, warning:""}
}