import * as React from 'react';
import PersonImageNormal from '../../components/layouts/PersonImageNormal';
import {useParams } from 'react-router-dom';

import { lectors } from '../../data/lectors';
import '../../assets/style_greeting.css';
import '../../assets/style.css';

import Footer from '../../components/footer/Footer';
import PersonImageTop from '../../components/layouts/PersonImageTop';
import Slider from '../../components/slider/Slider';
import Navbar from '../../components/nav/Navbar';
import PersonImageTopCvAbstract from '../../components/layouts/PersonImageTopCvAbstract';
import {isBrowser, isMobile } from 'react-device-detect';
import PersonImageTopCvAbstractMobile from '../../components/layouts/PersonImageTopCvAbstractMobile';

export default function LectorsSlide(){
    const [selection, setSelection] = React.useState(0)

    return (
        <>
        <Navbar/>
    <div className="flex-column centered" style={{minHeight:"60vh", alignItems:"center", paddingTop:"18vh"}}>
        <h1 className="font-gradient-large" style={{fontSize:"2.5rem", fontWeight:"400"}}>Pozvani predavači</h1>
    <div id="greeting">
    <div className="flex-row" style={{justifyContent:"center", fontSize:"1.5rem", alignItems:"center"}}><p style={{color:"black", marginRight:"0.5rem"}}>{selection==0?"Language choice:":"Odabir jezika:"}</p><div onClick={()=>{setSelection(0)}}  className={"choice-item "+ (selection==0?"choice-item-selected":"")}>HR</div><div onClick={()=>{setSelection(1)}}  className={"choice-item "+ (selection==1?"choice-item-selected":"")}>EN</div></div>
        {!isMobile?
        <Slider elements={
            Object.keys(lectors).map((key, index)=>{
                let person = lectors[key];
                return <PersonImageTopCvAbstract description={selection==0?person.description:person.description_en} title={person.title} name={person.name} lecture_text={selection==0?person.lecture_text:person.lecture_text_en} lecture_title={selection==0?person.lecture_title:person.lecture_title_en} image={person.image_vert} text={selection==0?person.text:person.text_en} lang={selection==0?"hr":"en"}/>
            })
        } number_visible={4} gap={6} scale={432}/>:
        <Slider elements={
            Object.keys(lectors).map((key, index)=>{
                let person = lectors[key];
                return <PersonImageTopCvAbstractMobile description={selection==0?person.description:person.description_en} title={person.title} name={person.name} lecture_text={selection==0?person.lecture_text:person.lecture_text_en} lecture_title={selection==0?person.lecture_title:person.lecture_title_en} image={person.image_vert} text={selection==0?person.text:person.text_en} lang={selection==0?"hr":"en"}/>
            })
        } number_visible={4} gap={0} scale={200}/>
        }
        </div>
    </div>
    <div className='background-overlay' style={{width:"100%"}}>
    <Footer/>
    </div>
    </>
    
)
}