import * as React from 'react';

import { api } from '../../environment';
import {useParams } from 'react-router-dom';
import { FormDataBuilder, makeFormControls } from '../../components/formElements/util';
import { isEmail, isEmpty, isTable } from '../../components/formElements/validators';
import Form from '../../components/formElements/Form';
import {Document, HeadingLevel, Packer, Paragraph, TextRun, AlignmentType} from 'docx';
import { saveAs } from 'file-saver';

export default function AbstractView(){
    const {id} = useParams()


    const [formData, setFormData] = React.useState(null);
    const [formControls, setFormControls] = React.useState(null);
    const [formTitle, setFormTitle] = React.useState("")
    const [modalHidden, setModalHidden] = React.useState(true);

    const typeSelectorData = [
        {name:"type", type:'select', label:'Oblik izlaganja', necessary: true, validators:[isEmpty],
    options:[
    {value:"1", text:"Usmeno izlaganje"},
    {value:"2", text:"Poster izlaganje"},
    {value:"3", text:"Simpozij"},
    {value:"4", text:"Okrugli stol"},
    {value:"5", text:"Radionica"},
    {value:"6", text:"Stručni domjenak"},
    {value:"7", text:"Sastanak stručne sekcije/radne skupine"},
    {value:"8", text:"Predstavljanje knjiga, psihologijskih mjernih instrumenata te drugih publikacija i uradaka"},
    {value:"9", text:'„Izvan okvira"'},
    {value:"10", text:"Psiholozi kao kreativci"},
    {value:"11", text:"Ostale aktivnosti"}],
    layoutStyle:{flex:'1 1 100%'},
    }
]

    const header = [{name:"order", label:"#"}, 
    {name:"first_name", label:"Ime", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"}, 
    {name:"last_name", label:"Prezime", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"}, 
    {name:"institution", label:"Institucija", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"title", label:"Titula", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}},
    {name:"city", label:"Grad", formField: {necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"state", label:"Država", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"actions", label:"Akcija", position:"sticky"}];

    const symposiumHeader = [
        {name:"order", label:"#"},
        {name:'active_participant',
        formField:{necessary:true, header:header, type:"tabular", validators:[isTable], layoutStyle:{flex: '1 1 100%'}, cols:['order', 'first_name', 'last_name', 'title', 'actions']}, 
        show:(arr)=>{return <div>{arr.active_participant[0].last_name + ", " + arr.active_participant[0].first_name}</div>}, label:'Autori', tableLabel:'Voditelj', layoutStyle:{flex:"1 1 100%"}},
        {name:'presenter', formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}}, label:'Ime i prezime predavača', layoutStyle:{flex:'1 1 45%'}},    
        {formField:{type:'subtitle', text:'Osoba za kontakt', layoutStyle:{flex:'1 1 100%'}}},
            {name:'contact', formField:{necessary:true, validators:[isEmpty], type:'text', layoutStyle:{flex:'1 1 45%'}}, label:'Ime i prezime', layoutStyle:{flex:'1 1 45%'}},
            {name:'contact_email', formField:{necessary:true, type:'text', validators:[isEmpty, isEmail], layoutStyle:{flex:'1 1 45%'}}, label:'e-mail adresa', layoutStyle:{flex:'1 1 45%'}},
            {formField:{type:'subtitle', text:'Podatci o izlaganju'}},
            {name:'activity_title',  formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 100%'}}, label:"Naslov", layoutStyle:{flex:'1 1 100%'}},
            {name:'abstract', formField:{necessary:true, type:"textarea", validators:[isEmpty], layoutStyle:{flex: '1 1 100%'}}, label:'Sažetak', layoutStyle:{flex:'1 1 100%'}},
            {name:'keywords', formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 100%'}}, label:'Ključne riječi', layoutStyle:{flex:'1 1 100%'}},
            {name:'note', formField:{necessary:false, type:"textarea", layoutStyle:{flex: '1 1 100%'}}, label:"Napomena", layoutStyle:{flex:"1 1 100%"}},
            {name:"actions", label:"Akcija", position:"sticky"}
    ]

    const thematicFields = [
        {value:"1", text:"Uloga i doprinos psihologije u zaštiti mentalnog zdravlja"},
        {value:"2", text:"Mentalno zdravlje i radna produktivnost u doba digitalne transformacije"},
        {value:"3", text:"Uloga i doprinos psihologa u sustavu odgoja i obrazovanja"},
        {value:"4", text:"Zaštitni i rizični čimbenici u prilagodbi na bolest"},
        {value:"5", text:"Izazovi za psihologe"},
        {value:"6", text:"Uloga i doprinos psihologije u interdisciplinarnoj suradnji"},
    ]

    const makeFormData = (type) =>{
        let newFormData = new FormDataBuilder();
        if(type!=="3"){
            newFormData = newFormData
            .addTabular("active_participant",  "Popis autora/aktivnih sudionika", true, header, [isTable],  {flex:"1 1 100%"}, ['order','first_name', 'last_name', 'title', 'actions'])
            .addTextField("presenter", "Ime i prezime predavača/voditelja aktivnosti", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("presenter_email", "e-mail adresa voditelja aktivnosti", true, true, [isEmpty], {flex:"1 1 45%"})
            .addSubtitle("Osoba za kontakt")
            .addTextField("contact", "Ime i prezime", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("contact_email", "e-mail adresa", true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
            
        } else {
            newFormData = newFormData
            .addSubtitle("Podatci o voditelju simpozija")
            .addTextField("first_name", "Ime", true, true, [isEmpty], {flex:"1 1 45%"}, null, "Podatci o voditelju simpozija")
            .addTextField("last_name", "Prezime", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("institution", "Institucija", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("title", "Titula", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("city", "Grad", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("state", "Država", true, true, [isEmpty], {flex:"1 1 45%"})
            .addSubtitle("Osoba za kontakt")
            .addTextField("contact", "Ime i prezime", true, true, [isEmpty], {flex:'1 1 45%'})
            .addTextField("contact_email", 'e-mail adresa', true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
        }
        newFormData = newFormData
        .addSubtitle("Podatci o aktivnosti")
        .addTextField("activity_title", "Naslov", true, true, [isEmpty], {flex:"1 1 100%"})
        .addSelectField("theme", "Tematsko područje", true, thematicFields, true, [isEmpty], {flex:"1 1 100%"})
        .addTextArea("abstract", "Sažetak", true, [isEmpty], {flex:"1 1 100%"})
        .addTextField("keywords", "Ključne riječi", true, true, [isEmpty], {flex:"1 1 100%"})

        if(type=="3"){
            newFormData = newFormData
            .addTabular("activity", "Popis usmenih izlaganja u okviru simpozija", true, symposiumHeader, [isTable], {flex:"1 1 100%"}, ['order', 'active_participant', 'activity_title', 'actions'])
        }
        
        if(type==="4"||type==="5"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"30", text:"30"},
                {value:"60", text:"60"},
                {value:"90", text:"90"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(type==="6"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"5", text:"5"},
                {value:"10", text:"10"},
                {value:"15", text:"15"},
                {value:"20", text:"20"},
                {value:"25", text:"25"},
                {value:"30", text:"30"},
                {value:"35", text:"35"},
                {value:"40", text:"40"},
                {value:"45", text:"45"},
                {value:"50", text:"50"},
                {value:"55", text:"55"},
                {value:"60", text:"60"},
                {value:"65", text:"65"},
                {value:"70", text:"70"},
                {value:"75", text:"75"},
                {value:"80", text:"80"},
                {value:"85", text:"85"},
                {value:"90", text:"90"},
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(type==="7"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"30", text:"30"},
                {value:"60", text:"60"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(type==="8"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"15", text:"15"},
                {value:"30", text:"30"},
                {value:"60", text:"60"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(type==="9"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"5", text:"5"},
                {value:"10", text:"10"},
                {value:"15", text:"15"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        }
        newFormData=newFormData.addTextArea("note", "Napomena", false, [], {flex:"1 1 100%"})
        .addSelectField('status', 'Status prijave', false, [
            {value:'0', text:'Čeka pregled'},
            {value:'1', text:'Na recenziji'},
            {value:'2', text:'Prihvaćena'},
            {value:'3', text:'Prihvaćena uz izmjene'},
            {value:'4', text:'Odbijena'}
        ], true, [isEmpty], {flex:"1 1 100%"});

        return newFormData.get()
    }

    const getData = () => {
        let call = fetch(api+"/get_abstract/"+id,{
            method: "POST",
            body:JSON.stringify({
                token:localStorage.getItem('site')
            })
            })
            try{
        return call.then(response=>response.json())
            } catch(err){
                console.log(err);
                return {}
            }
    }

    const [formType, setFormType] = React.useState(makeFormControls(typeSelectorData));

    const loadData = (()=>{
        getData().then(
            (data)=>{
                setFormType((prevState)=>{
                    prevState.type['value'] = data.type.toString();
                    return prevState
                
            })
            setFormData(()=>{
                return makeFormData(data.type)
            })
            setFormControls(()=>{
                let formControls = makeFormControls(makeFormData(data.type))
                for(let key in data){
                    if(formControls[key]!=undefined){
                        formControls[key].value=data[key]
                    }
                }
                return formControls});
            
        })
    })

    React.useEffect(()=>{
        loadData();
    }, []);

    const mapType = (value)=>{
        const values = {
            "1": "Usmeno izlaganje",
            "2": "Poster izlaganje",
            "3": "Simpozij",
            "4": "Okrugli stol",
            "5": "Radionica",
            "6": "Stručni domjenak",
            "7": "Sastanak stručne sekcije/radne skupine",
            "8": "Predstavljanje knjiga, psihologijskih mjernih instrumenata te drugih publikacija i uradaka",
            "9": "Izvan okvira",
            "10": "Psiholozi kao kreativci",
            "11": "Ostalo"
        }
        return values[value];
    }

    const mapTheme = (value) =>{
        const values = {
            "1": "Uloga i doprinos psihologije u zaštiti mentalnog zdravlja",
            "2": "Mentalno zdravlje i radna produktivnost u doba digitalne transformacije",
            "3": "Uloga i doprinos psihologa u sustavu odgoja i obrazovanja",
            "4": "Zaštitni i rizični čimbenici u prilagodbi na bolest",
            "5": "Izazovi za psihologe",
            "6": "Uloga i doprinos psihologije u interdisciplinarnoj suradnji"
        }
        return values[value]
    }

    function saveDocumentToFile(doc, fileName) {
        const mimeType ="application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        Packer.toBlob(doc).then(blob => {
            const docblob = blob.slice(0, blob.size, mimeType);
            saveAs(docblob, fileName);
        });
    }

    const exportAbstract = () => {
        console.log(formType)
        const doc = new Document({
            sections:[{
            children: [
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Naslov: ',
                            bold: true
                        }),
                        new TextRun({
                            text:formControls['activity_title'].value
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Oblik izlaganja: ',
                            bold: true
                        }),
                        new TextRun({
                            text:mapType(formType.type.value)
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Ključne riječi: ',
                            bold: true
                        }),
                        new TextRun({
                            text:formControls['keywords'].value
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Tematsko područje: ',
                            bold: true
                        }),
                        new TextRun({
                            text: mapTheme(formControls['theme'].value)
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Tekst sažetka: ',
                            bold: true
                        }),
                        new TextRun({
                            text:formControls['abstract'].value
                        })
                    ]
                })
            
            ]
        }
        ]
    })

    let title = formControls['activity_title'].value.replace(/ /g,"_").toLowerCase();

        saveDocumentToFile(doc, title)
    }

    const exportSymposium = () => {
        console.log(formType)
        let docObj = {
            sections:[{
            children: [
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Naslov: ',
                            bold: true
                        }),
                        new TextRun({
                            text:formControls['activity_title'].value
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Oblik izlaganja: ',
                            bold: true
                        }),
                        new TextRun({
                            text:mapType(formType.type.value)
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Ključne riječi: ',
                            bold: true
                        }),
                        new TextRun({
                            text:formControls['keywords'].value
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Tematsko područje: ',
                            bold: true
                        }),
                        new TextRun({
                            text: mapTheme(formControls['theme'].value)
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Tekst sažetka: ',
                            bold: true
                        }),
                        new TextRun({
                            text:formControls['abstract'].value
                        })
                    ]
                })
            
            ]
        },
        ]
    }
        

    for(let activity of formControls['activity'].value){
        docObj.sections.push({
            children: [
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Naslov: ',
                            bold: true
                        }),
                        new TextRun({
                            text:activity['activity_title']
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Oblik izlaganja: ',
                            bold: true
                        }),
                        new TextRun({
                            text:mapType("1")
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Ključne riječi: ',
                            bold: true
                        }),
                        new TextRun({
                            text:activity['keywords']
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Tematsko područje: ',
                            bold: true
                        }),
                        new TextRun({
                            text: mapTheme(formControls['theme'].value)
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            text:'Tekst sažetka: ',
                            bold: true
                        }),
                        new TextRun({
                            text:activity['abstract']
                        })
                    ]
                })
            
            ]
        },)
    }

    const doc = new Document(docObj)

    let title = formControls['activity_title'].value.replace(/ /g,"_").toLowerCase();

        saveDocumentToFile(doc, title)
    }

    const handleUpdateStatus = () =>{
        let call = fetch(api+"/update_activity",{
            method: "POST",
            body:JSON.stringify({
                token:localStorage.getItem('site'),
                activity: {id:id, ...formControls}
            })
            })
    }

    //FUNKCIJA

    const exportAbstractBook = () => {

        let active_participants = formControls['active_participant'].value

        const getAuthors = ()=>{
            let authors=""
            for(let author of active_participants){
                authors = authors.concat(author['first_name'].trim(), ' ', author['last_name'].trim(), ', ')
            }
            return authors.substring(0, authors.length-2);
        }

        let abstract = {
            title: formControls['activity_title'].value.toUpperCase(),
            authors: getAuthors(),
            institution_city: active_participants[0].institution + ", " + active_participants[0].city,
            contact: '✉' + ' ' + formControls['presenter_email'].value,
            abstract: formControls['abstract'].value,
            keywords: formControls['keywords'].value

        }

        console.log("ABSTRACT", abstract);

        const doc = new Document({
            font:'Cambria',
            size: '11pt',
            sections:[{
            children: [
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.title,
                            bold: true,
                            
                        })
                    ],
                    alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.authors,
                            bold: true
                        }),
                    ]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.institution_city
                        }),
                    ]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.contact,
                            italics:true
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.abstract
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            bold: true,
                            text:"Ključne riječi: "
                        }),
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.keywords
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED
                }),
            
            ]
        }
        ]
    })

    let title = formControls['activity_title'].value.replace(/ /g,"_").toLowerCase();

        saveDocumentToFile(doc, title)
    }


    //FUNKCIJA

    const exportSymposiumBook = () => {

        let active_participants = formControls['active_participant'].value
        
        const getAuthors = ()=>{
            let authors=""
            for(let author of active_participants){
                authors = authors.concat(author['first_name'].trim(), ' ', author['last_name'].trim(), ', ')
            }
            return authors.substring(0, authors.length-2);
        }

        let abstract = {
            title: formControls['activity_title'].value.toUpperCase(),
            authors: getAuthors(),
            institution_city: active_participants[0].institution + ", " + active_participants[0].city,
            contact: '✉' + ' ' + formControls['presenter_email'].value,
            abstract: formControls['abstract'].value,
            keywords: formControls['keywords'].value

        }

        let docObj = {
            
            sections:[{
            children: [
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.title,
                            bold: true,
                            
                        })
                    ],
                    alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.authors,
                            bold: true
                        }),
                    ]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.institution_city
                        }),
                    ]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.contact,
                            italics:true
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.abstract
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            bold: true,
                            text:"Ključne riječi: "
                        }),
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.keywords
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED
                }),
            
            ]
        }
        ]
    }
        
    for(let activity of formControls['activity'].value){
        let active_participants = activity['active_participant']
        
        const getAuthors = ()=>{
            let authors=""
            for(let author of active_participants){
                authors = authors.concat(author['first_name'].trim(), ' ', author['last_name'].trim(), ', ')
            }
            return authors.substring(0, authors.length-2);
        }

        let abstract = {
            title: activity['activity_title'].toUpperCase(),
            authors: getAuthors(),
            institution_city: active_participants[0].institution + ", " + active_participants[0].city,
            contact: '✉' + ' ' + activity['presenter_email'],
            abstract: activity['abstract'],
            keywords: activity['keywords']

        }

        docObj.sections.push({
            children: [
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.title,
                            bold: true,
                            
                        })
                    ],
                    alignment: AlignmentType.CENTER
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.authors,
                            bold: true
                        }),
                    ]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.institution_city
                        }),
                    ]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text: abstract.contact,
                            italics:true
                        })
                    ]
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.abstract
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED
                }),
                new Paragraph({
                    children:[]
                }),
                new Paragraph({
                    children:[
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            bold: true,
                            text:"Ključne riječi: "
                        }),
                        new TextRun({
                            font:'Cambria',
            size: '11pt',
                            text:abstract.keywords
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED
                }),
            
            ]
    },)
    }

    const doc = new Document(docObj)

    let title = formControls['activity_title'].value.replace(/ /g,"_").toLowerCase();

        saveDocumentToFile(doc, title)
    }
    


    return(<>
    <Form formControls={formType} setFormControls={setFormType} formData={typeSelectorData} />
        
        {
            formData != null?
            <>
            <h2 className="form-subtitle">{formTitle}</h2>
            <div className="flex-row" style={{gap:"1.5rem", flexWrap:"wrap"}} >
            <Form formControls={formControls} formData={formData} setFormControls={setFormControls}/>
            <div className="flex-row">
                <button className="modal-button" style={{margin:"0.5rem"}} onClick={()=>{formType.type.value!="3"?exportAbstract():exportSymposium();}}>Izvoz za recenziju</button>
                <button className="modal-button" style={{margin:"0.5rem"}} onClick={()=>{formType.type.value!="3"?exportAbstractBook():exportSymposiumBook();}}>Izvoz za knjigu sažetaka</button>
                <button className="modal-button" style={{margin:"0.5rem"}} onClick={()=>{handleUpdateStatus();}}>Promijeni status</button>
            </div>
            </div></>:<></>
        
        }
    </>)

}