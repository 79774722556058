import * as React from 'react';

import { api } from '../../environment';
import {useParams } from 'react-router-dom';
import Form from '../../components/formElements/Form';
import { isEmail, isEmpty } from '../../components/formElements/validators';
import { makeFormControls } from '../../components/formElements/util';
import Table from '../../components/table/Table';
import Select from '../../components/formElements/Select';
import Modal from '../../components/modal/Modal';
import { abort } from 'process';

export default function ApplicationView(){

    const {id} = useParams()

    

    const [pricingOptions, setPricingOptions] = React.useState([{value:"1", text:"Rana"}, {value:"2", text:"Srednja"}, {value:"3", text:"Kasna"}, {value:"4", text:"Posebna"}, {value:"5", text:"Upravni/Organizacijski/Programski odbor - HPD"}])

    const [departureDisabled, setDepartureDisabled] = React.useState(true)
    const [departureDates, setDepartureDates] = React.useState([])
    const [peopleNumber, setPeopleNumber] = React.useState(0)
    const [hotelVisible, setHotelVisible] = React.useState(false);
    const [statusData, setStatusData] = React.useState({status:{value:"", validity:{valid:true}}});
    


    const makePricingArray = (value) =>{
        let pricings = ['Rana', 'Srednja', 'Kasna', 'Posebna', 'Upravni/Organizacijski/Programski odbor - HPD'];
        let prices = {
            "1" : [150, 225,300,50, 0],
            "2" : [225, 300, 375, 125, 0] 
        }
        if(value=="2"){
            pricings = ['Rana', 'Srednja', 'Kasna', 'Posebna'];
            prices = {
                "1" : [150, 225,300,50],
                "2" : [225, 300, 375, 125] 
            }
        }

        return pricings.map((pricing, index)=>{
            return {value: (index+1).toString(), text: pricing + ' - ' + prices[value][index].toString() + " €" }
        })

    }

    const makeDepartureDates = (value)=>{
        const departureDates = ['7.11.2024', '8.11.2024', '9.11.2024'];

        return departureDates.slice(Number(value)-6, departureDates.length).map((date, index)=>{
            return {value:date[0], text:date}
        })
    }

    

    const participantFormData=[
        {name:'first_name', type:'text', label:'ime', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'last_name', type:'text', label:'prezime', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'oib', type:'text', label:'oib', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'address', type:'text', label:'adresa', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'state', type:'text', label:'država', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'city', type:'text', label:'grad', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'phone', type:'text', label:'telefon', necessary:false, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'mobile', type:'text', label:'mobitel', necessary:false, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'e_mail', type:'text', label:'osobna e-mail adresa', necessary:true, validators:[isEmpty, isEmail], layoutStyle:{flex:'1 1 45%'}},
        {name:'hpk_number', type:'text', label:'broj ovlaštenja HPK', necessary:false, validators: [isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'hpd_membership', type:'select', label:'Stanje članstva HPD', defaultValue:"", necessary:true, 
            options:[{value:"1", text:"Da"}, {value:"2", text:"Ne"}], validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}, 
            callback:(e)=>{
                setPricingOptions(makePricingArray(e.target.value))
            }},
        {name:'payment_type', type:'select', label:'Vrsta kotizacije', defaultValue:"", necessary:true,
            options:pricingOptions, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
            {name:"status", label:"Status prijave", options:[
                {value:"1", text:"Odobren"},
                {value:"0", text:"Čeka pregled"},
                {value:"-1", text:"Odbijen"}
            ], type:"select", style:{borderColor:"#223d69", color:"#223d69"}}
    ]

    const hotelFormData=[
        {name:'hotel', type:'select', label:'hotel', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'1', text:'Hotel Ambasador (5 zvjezdica)'},
                {value:'2', text:'Hotel Imperial (4 zvjezdice)'},
                {value:'3', text:'Hotel Admiral (4 zvjezdice'},
                {value:'4', text:'Hotel Palace Bellevue (4 zvjezdice'},
                {value:'5', text: 'Hotel Istra (3 zvjezdice)'}
            ], layoutStyle:{flex:'1 1 100%'}},
        {name:'person_count', type:'select', label:'broj osoba u sobi', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'1', text:'1'},
                {value:'2', text:'2'}
            ], layoutStyle:{flex:'1 1 100%'},
        callback:(e)=>{
            setPeopleNumber(Number(e.target.value))
        }}, 
        {name: 'date_arrival', type:'select', label:'datum dolaska', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'6', text:'6.11.2024'},
                {value:'7', text:'7.11.2024'},
                {value:'8', text:'8.11.2024'}
            ], layoutStyle:{flex:'1 1 45%'},
            callback: (e)=>{
                setDepartureDates(makeDepartureDates(e.target.value));
                setDepartureDisabled(false);
            }
        },
        {name: 'date_departure', type:'select', label:'datum odlaska', necessary:true, formNecessary:false, validators:[isEmpty],
            options:[
                {value:'7', text:'7.11.2024'},
                {value:'8', text:'8.11.2024'},
                {value:'9', text:'9.11.2024'}
            ], layoutStyle:{flex:'1 1 45%'},
            
        },
        {name:'name_roommate', type:'text', label:'ime i prezime druge osobe u sobi', necessary:peopleNumber==2, validators:[isEmpty], formNecessary:false, layoutStyle:{flex:'1 2 45%'}, hidden:peopleNumber<2},
        {name:'note', type:'textarea', label:'napomena', necessary:false, formNecessary:false, layoutStyle:{flex:'1 1 100%'}},
    ]



    const paymentProviderFormData=[
        {name:'name', type:'text', label:'naziv platitelja', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'address', type:'text', label:'adresa platitelja', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'oib', type:'text', label:'oib platitelja', necessary:true, validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}},
        {name:'email', type:'text', label:'e-mail adresa platitelja', necessary:true, validators:[isEmpty, isEmail], layoutStyle:{flex:'1 1 45%'}},
        {name:'payment_way', type:'select', label:'način plaćanja', necessary:true, validators:[isEmpty],
            options:[
                {value:'1', text:'Bankovna transakcija'},
                {value:'2', text:'Kreditna kartica'}
            ], layoutStyle:{flex:'1 1 100%'}}
    ]

    const [participantFormControls, setParticipantFormControls] = React.useState(makeFormControls(participantFormData));
    const [hotelFormControls, setHotelFormControls] = React.useState(makeFormControls(hotelFormData));
    const [paymentProviderFormControls, setPaymentProviderFormControls] = React.useState(makeFormControls(paymentProviderFormData));

    const getData = () => {
        let call = fetch(api+"/get_participant/"+id,{
            method: "POST",
            body:JSON.stringify({
                token:localStorage.getItem('site')
            })
            })
            try{
        return call.then(response=>response.json())
            } catch(err){
                console.log(err);
                return {}
            }
    }

    React.useEffect(()=>{
        getData().then((data)=>{
            let booking = data.booking;
            let payment = data.payment;
            let participantValues = structuredClone(participantFormControls);
            let bookingValues = structuredClone(hotelFormControls); 
            let paymentValues=structuredClone(paymentProviderFormControls);
            for(let key in data){
                if(key!='booking' && key!='payment_provider'&&participantValues[key]!=undefined){
                    participantValues[key].value=data[key]
                }
            }
            for(let key in booking){
                if(bookingValues[key]!=undefined) bookingValues[key].value = booking[key]
            }

            for (let key in payment){
                if(paymentValues[key]!=undefined) paymentValues[key].value = payment[key];
            }
            setParticipantFormControls(participantValues);
            setHotelFormControls(bookingValues);
            setPaymentProviderFormControls(paymentValues);
            setStatusData({status:{value:participantFormControls.status.value, validity:{valid:true}}});
            setPeopleNumber(hotelFormControls.person_count);
        })
        
    }, [])

    const pricingTableHeader = [
        {name:"order", label:"#"},
        {name:"description", label:"opis"},
        {name:"price", label:"cijena"}
    ]

    const makePaymentDescription = (isMember, paymentType) => {
        if(paymentType=="5"&&isMember=="1"){
            return "Kotizacija za članove Upravnog/Organizacijskog/Programskog odbora"
        }
        const pricings = ['Rana', 'Srednja', 'Kasna', 'Posebna'];
        return isMember!=""&&paymentType!=""&&paymentType!="5"?`${pricings[Number(paymentType)-1]} kotizacija ${isMember=="1"?"za članove HPD-a":""}`:"";
    }

    const makePaymentPrice = (isMember, paymentType)=>{
        const prices = {
            "1" : [150, 225,300,50, 0],
            "2" : [225, 300, 375, 125] 
        }
        return isMember!=""&&paymentType!=""&&paymentType!="5"?prices[isMember][Number(paymentType)-1]:0
    }

    const makeHotelPriceDescription = () => {
        let number_nights = Number(hotelFormControls['date_departure'].value)-Number(hotelFormControls['date_arrival'].value)
        let show = hotelFormControls['person_count'].value != "" && number_nights != 0;
        
        if(participantFormControls['payment_type'].value=="5"){
            return show?"Cijena boravka za članove Upravnog/Organizacijskog/Programskog odbora":""
        }
       
        return show?`Cijena boravka (${hotelFormControls['person_count'].value=="1"?"jedna osoba":"dvije osobe"}) za ${number_nights==1?"jedno noćenje":number_nights==2?"dva noćenja":'tri noćenja'} na bazi ${hotelFormControls['hotel'].value=="1"?"pansiona s doručkom i ručkom.":"noćenja s doručkom."}`:""
    }

    const computeHotelpricing = (peopleNumber, hotel) => {
        const prices = {
            "" : [],
            "1": [162, 100, 100, 106, 95],
            "2": [230, 126, 126, 132, 116]
        }
        if(peopleNumber!=""&&hotel!=""){
            if(participantFormControls['payment_type'].value=="5"){
                return ""
            }
            return prices[peopleNumber][Number(hotel)-1]
        } else return ""
        
    }

    const sumPricing = () => {
        return (Number(hotelFormControls['date_departure'].value)-Number(hotelFormControls['date_arrival'].value))*computeHotelpricing(hotelFormControls['person_count'].value, hotelFormControls['hotel'].value)+makePaymentPrice(participantFormControls['hpd_membership'].value, participantFormControls['payment_type'].value);
    }

    const pricingTableRows = [
        {order: "1.", description:makePaymentDescription(participantFormControls['hpd_membership'].value,participantFormControls['payment_type'].value), 
        price:makePaymentPrice(participantFormControls['hpd_membership'].value,participantFormControls['payment_type'].value).toString()+'.00 €'},
        {order: "2.", description:makeHotelPriceDescription(), price:`${computeHotelpricing(hotelFormControls['person_count'].value, hotelFormControls['hotel'].value)*(hotelFormControls['date_departure'].value!=""?Number(hotelFormControls['date_departure'].value)-Number(hotelFormControls['date_arrival'].value):0)}.00 €`},
        {order: "", description:"", price:`total: ${sumPricing().toString()}.00 €`}
    ]

    const toggleNecessary = (formControls) => {
        let formControlsCopy = structuredClone(formControls);
        for(let key in formControlsCopy){
            if(formControlsCopy[key].validity.formNecessary!=null){
                formControlsCopy[key].value = "";
                formControlsCopy[key].validity.formNecessary = !formControlsCopy[key].validity.formNecessary;
            }
        }
        setHotelFormControls(formControlsCopy);
    }

    const paymentPricingHeader = [
        {name:"payment_type", label:"", width:"25%"},
        {name:"hpd_members", label:"Članovi HPD-a*", width:"45%"},
        {name:"rest", label:"Ostali", width:"20%"}
    ]

    const paymentPricingBody = [
        {payment_type:"Rana (do 31.07.2024.)", hpd_members:"150.00 €", rest:"225.00 €"},
        {payment_type:"Srednja (od 1.8.2024. do 13.10.2024.)", hpd_members:"225.00 €", rest:"300.00 €"},
        {payment_type:"Kasna (od 14.10.2024. do 5.11.2024)", hpd_members:"300.00 €", rest:"375.00 €"},
        {payment_type:"Posebna**", hpd_members:"50.00 €", rest:"125.00 €"}
    ]

    const [modalHidden, setModalHidden] = React.useState(true);

    const handleUpdateStatus = () =>{
        let call = fetch(api+"/update_participant",{
            method: "POST",
            body:JSON.stringify({
                token:localStorage.getItem('site'),
                participant: {id:id, ...participantFormControls}
            })
            })
    }

    

    return (
        <>
        <h1 style={{marginBottom:"2rem"}}>Pregled prijave</h1>
        <div>
            <h2>Osobni podatci</h2>
            <div className="flex-row" style={{flexWrap:"wrap", gap:"1.2rem"}}>
            <Form formData={participantFormData} formControls={participantFormControls} setFormControls={setParticipantFormControls}/>
            </div>
            <h2>Podatci o smještaju</h2>
            <div className="flex-row" style={{flexWrap:"wrap", gap:"1.2rem"}}>
            <Form formData={hotelFormData} formControls={hotelFormControls} setFormControls={setHotelFormControls}/>
            </div>
            <h2>Podatci o platitelju</h2>
            <div className="flex-row" style={{flexWrap:"wrap", gap:"1.2rem"}}>
            <Form formData={paymentProviderFormData} formControls={paymentProviderFormControls} setFormControls={setPaymentProviderFormControls}/>
            </div>
            <div className="table-wrap">
                    <h2 className="form-subtitle">Izračun cijene sudjelovanja</h2>
                    <Table header={pricingTableHeader} dataSource={pricingTableRows}/>
                </div>
                <button className="modal-button" style={{margin:"2rem"}} onClick={()=>{handleUpdateStatus();setModalHidden(false);}}>Promijeni status</button>
        </div>
        <Modal hidden={modalHidden} title="Podatci uspješno promijenjeni!" content={<p>Kliknite na "U redu" za povratak na pregled prijave</p>} buttons={[<button className="modal-button" onClick={()=>{setModalHidden(true);window.location.reload()}}>U redu</button>]}/>
        </>
    )
}