
import josip from '../assets/imgs/josip_lopizic_cut.jpg';
import mladenka from '../assets/imgs/mladenka_cut.jpg';
import hrvoje from '../assets/imgs/gligora_q.jpg';

import josip_vert from'../assets/imgs/josip_lopizic_vert.png';
import mladenka_vert from '../assets/imgs/mladenka_tkalcic_vert.png';
import hrvoje_vert from '../assets/imgs/hrvoje_gligora_vert.png';

export const greetings = {
    'josip-lopizic':{
        name:"Josip Lopižić",
        title:"mr. spec.",
        section_title:"Pozdravna riječ predsjednika Hrvatskog psihološkog društva",
        description:"Predsjednik Hrvatskog psihološkog društva",
        link:'/pozdravna-rijec/josip-lopizic',
        image: josip,
        image_vert:josip_vert,
        text: "Poštovane kolegice, poštovani kolege,\n\n\
        Zadovoljstvo nam je pozvati vas na još jednu godišnju konferenciju hrvatskih psihologa s\
        međunarodnim sudjelovanjem koja će se po peti put održati u Opatiji, u Kongresnom centru Hotela\
        Ambasador, od 6. do 9. studenoga 2024. godine. Središnja tema ove konferencije je „Suvremeni\
        izazovi mentalnog zdravlja u različitim radnim okruženjima“.\n\n\
        Dakle, i dalje se bavimo mentalnim zdravljem, ali ovaj put s izazovima vezanima za organizaciju i\
        proces rada. Kakve su naše mogućnosti u očuvanju mentalnog zdravlja na radnom mjestu, kako\
        prepoznati probleme vezanih za mentalno zdravlje u pojedinoj organizaciji, u čemu je uloga naše\
        struke u razvijanju različitih programa za poslodavce i radnike, kako u prevenciji i smanjenju\
        psihosocijalnog stresa, stresa na radu do razvijanja optimalnih uvjeta u radnim okruženjima kao i\
        intervencija kad je psihosocijalna klima narušena najrazličitijim čimbenicima. Možemo li i kako razviti\
        i postaviti standarde za zaštitu mentalnog zdravlja u različitim radnim okruženjima? Samo je dio\
        tema i pitanja na koja ćemo pokušati odgovoriti na ovoj konferenciji.\n\n\
        Ova 31. godišnja konferencija, naš godišnji susret u Opatiji, izvrsna je prilika za susret kolegica i\
        kolega, za nova učenja vezanima ponajprije za psihologijsku praksu ali i za upoznavanje s novim\
        područjima psihologijske djelatnosti i izazovima koji su pred nama. Konferencije su oduvijek bile\
        središnji godišnji skupovi psihologinja i psihologa te mjesta druženja i susreta kolega i prijatelja. Naši\
        ljubazni domaćini, Društvo psihologa Primorsko-goranske županije najavljuje i vrlo lijepi društveni\
        program te brojne sadržaje.\n\n\
        Dobrodošli u Opatiju, dobrodošli na 31. godišnju konferenciju hrvatskih psihologa!\n\n\
        Josip Lopižić, predsjednik Hrvatskog psihološkog društva"
    },
    'mladenka-tkalcic':{
        name:"Mladenka Tkalčić",
        title:"dr. sc.",
        section_title:"Pozdravna riječ predsjednice Programskog odbora",
        description:"Predsjednica Programskog odbora",
        link:'/pozdravna-rijec/mladenka-tkalcic',
        image: mladenka,
        image_vert: mladenka_vert,
        text:"Cijenjene i drage kolegice i kolege,\n\n\
        zadovoljstvo nam je najaviti 31. godišnju konferenciju hrvatskih psihologa koja će se 2024. godine održati u Opatiji.\
        Glavna tema ove Konferencije je „Suvremeni izazovi mentalnog zdravlja u različitim radnim okruženjima“. Prevencija problema i promicanja mentalnog zdravlja u različitim radnim okruženjima, uključujući i sustav odgoja i obrazovanja, odnos mentalnoga zdravlja i radne produktivnosti u doba digitalne transformacije, kao i utjecaj dobne i kulturalne raznolikosti na psihološku dobrobit na različitim mjestima rada, samo su neke od podtema ovogodišnje Konferencije.\n\n\
        Mentalno zdravlje jedan je od javnozdravstvenih prioriteta Svjetske zdravstvene organizacije, te je, s obzirom na težinu problema i posljedica koje mentalni poremećaji imaju na društvo u cjelini, naglasak stavljen na kreiranje i provođenje mjera promicanja i zaštite mentalnoga zdravlja u kojima naša struka ima ključnu ulogu. Naša stručna znanja i vještine, temeljene na znanstvenim spoznajama, omogućuju nam suočavanje s raznolikim izazovima koje pred nas postavlja suvremeni način življenja. U suradnji sa stručnjacima iz drugih znanstvenih disciplina, psiholozi su uključeni u očuvanje i unaprjeđenje mentalnog zdravlja, prevenciju i rano prepoznavanje problema mentalnoga zdravlja, kreiranje i provođenje biopsihosocijalnih intervencija, te zaštitu mentalnog zdravlja s ciljem poboljšanja cjelokupne kvalitete života kako pojedinaca tako i šire zajednice.\n\n\
        Kroz pozvana predavanja priznatih domaćih i inozemnih predavača, standardne oblike rada poput usmenih izlaganja, vođenih poster-prezentacija, simpozija, okruglih stolova i radionica, ali i stručnih domjenaka, predstavljanja psihologijskih mjernih instrumenata i knjiga, te kratkih inspirativnih prikaza aktualnih psihologijskih tema raspravit ćemo o tome kako se kao struka prilagođavamo sve većim osobnim i društvenim izazovima i potrebama, što možemo ponuditi pojedincima i društvu u cjelini s posebnim naglaskom na jačanje ljudskih resursa i unaprjeđenja uvjeta rada u različitim radnim okruženjima, jer su zaposlenost, mjesto i organizacija rada bitne odrednice mentalnog zdravlja.\n\n\
        Pozivamo vas, psihologinje i psihologe, kao i druge zainteresirane stručnjake, da sudjelujete na ovoj Konferenciji i u ugodnom radnom okruženju kroz razgovor s kolegicama i kolegama razmijenite svoja profesionalna i osobna iskustva pridonoseći razvoju i promociji naše struke.\
        Dobro došli na 31. godišnju konferenciju hrvatskih psihologa, dobro došli u Opatiju!\n\n\
        Mladenka Tkalčić,\n\
        predsjednica Programskog odbora 31. godišnje konferencije hrvatskih psihologa"
    },
    'hrvoje-gligora':{
        name:"Hrvoje Gligora",
        title: "mag. psyh.",
        section_title:"Pozdravna riječ predsjednika Organizacijskog odbora",
        description:"Predsjednik Organizacijskog odbora",
        link:'/pozdravna-rijec/hrvoje-gligora',
        image: hrvoje,
        image_vert: hrvoje_vert,
        text:"Drage kolegice i kolege,\n\n\
        osam je godina prošlo od našeg posljednjeg konferencijskog susreta na Kvarneru,\
        stoga nam je izrazito zadovoljstvo što ćemo se opet susresti u Opatiji na 31. godišnjoj\
        konferenciji hrvatskih psihologa koja se održava od 06. do 09. studenog 2024.\
        godine. Nadamo se da će konferencijski sadržaji i programi okupiti značajan broj\
        sudionika, i to ne samo psihologa, a ambijenti Hotela Kvarner i Ambasador u kojima\
        se odvija program doprinijet će ugodnoj i produktivnoj atmosferi u danima našeg\
        najvećeg strukovnog okupljanja. Neslužbeni dio programa nastojat ćemo obogatiti i\
        nekim sadržajima specifičnim za ovaj kraj, te s nestrpljivošću očekujemo ovogodišnji\
        kvarnerski susret kojeg u konferencijskom vikendu zaslađuje i tradicionalni opatijski\
        Festival čokolade.\n\n\
        Prošećite se (i zasladite) znamin po Kvarneru!\n\n\
        Za Organizacijski odbor 31GKHP:\n\
        Hrvoje Gligora"
    }
    
}